import React from "react"

const Index_page = ({data}) => (
    <>
        <br/><br/>
        <center>The Gatsby site is working.</center>
    </>
)

export default Index_page
